
export default {
  data() {
    return {
      scrollPosition: null,
      isAffShowed: false,
      sectionsPositions: {
        main: [],
        tablo: [],
        affiliator: [],
        news: [],
      },
    };
  },
  computed: {
    isHeaderLight() {
      return (this.scrollPosition > this.sectionsPositions.tablo[0] && this.scrollPosition < this.sectionsPositions.tablo[1]) ||
        (this.scrollPosition > this.sectionsPositions.news[0] && this.scrollPosition < this.sectionsPositions.news[1]);
    },
  },
  watch: {
    scrollPosition: {
      handler(val) {
        if (this.$route.name === 'index' && val > (this.sectionsPositions.main[0] / 2)) {
          if (window && !window.customElements.get('razlet-affiliator')) this.$bus.$emit('load-aff');
          else if (!this.isAffShowed) {
            this.isAffShowed = true; 
            this.$bus.$emit('show-aff');
          }
        }
      },
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    if (this.$route.name === 'index') {
      this.fillSectionsCoords();
      this.$nuxt.$on('tablo-loaded', () => this.fillSectionsCoords());
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
    this.$nuxt.$off('tablo-loaded');
  },
  destroyed() {
    if (this.$nuxt) this.$nuxt.$emit('route-changed');
  },
  methods: {
    fillSectionsCoords() {
      Object.keys(this.sectionsPositions).forEach(key => {
        this.fillSectionCoords(key);
      });
    },
    fillSectionCoords(key) {
      const el = document.getElementsByClassName(`section-${key}`)[0];
      if (el) this.sectionsPositions[key] = [el.offsetTop, el.offsetTop + el.offsetHeight];
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
};
